@use '../utilities/mixin/font';
@use '../utilities/mixin/link';

// Overrides for Bootstrap variables:

$primary: $blue;
$secondary: $gray-700;
$success: $green;
$info: $blue;
$danger: $red;
$warning: $rose;
$light: $gray-100;
$dark: $gray-900;

$body-bg: $white;
$body-color: $gray-900;

$border-color: $blue-200;
$form-select-border-color: $gray-500;

$accordion-color: $body-color;
$accordion-border-color: $blue-200;
$accordion-bg: $blue-100;
$accordion-border-width: 0;
$accordion-button-color: $body-color;
$accordion-button-active-color: $accordion-button-color;
$accordion-button-bg: $body-bg;
$accordion-button-active-bg: $accordion-button-bg;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $accordion-icon-color;
$accordion-button-white-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-pill: 0;

$table-color: $gray-900;
$table-bg: $white;
$table-striped-color: $gray-900;
$table-striped-bg: $gray-200;
$table-hover-color: $gray-900;
$table-hover-bg: $blue-150;
$table-header-color: $white;
$table-header-bg: $primary;
$table-border-color: $blue-200;

$form-check-input-border: 1px solid $gray-500;
$form-check-input-border-radius: 15%;
$form-switch-color: $secondary;
$form-label-margin-bottom: 0.3em;

$grid-gutter-width: 1.5rem;

$font-family-sans-serif: font.$frutiger-light;
$font-size-base: font.$regular-font-size;
$font-size-sm: font.$small-font-size;
$font-size-lg: font.$large-font-size;

$line-height-base: font.$regular-line-height;
$line-height-sm: font.$small-line-height;
$line-height-lg: font.$large-line-height;

$small-font-size: font.$small-font-size;

$headings-font-family: font.$frutiger-medium-cond;
$headings-font-weight: 300;
$headings-margin-bottom: 1.5rem;
$h1-font-size: 2.375rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.625rem;
$h4-font-size: 1.375rem;
$h5-font-size: font.$regular-font-size;
$headings-line-height: 1.3;

$breadcrumb-margin-bottom: 0.5rem;

$link-color: inherit;
$link-hover-color: var(--link-hover-color);

$link-shade-percentage: 0;
$link-hover-color: $link-color;
$link-hover-decoration: null;

$focus-outline-width: 0.25rem;
$focus-outline-opacity: 1;
$focus-outline-color: $rose;

$input-btn-border-width: 2px;
$input-border-color: $gray-500;
$input-focus-border-color: $rose;

$input-btn-focus-width: $focus-outline-width;
$input-btn-focus-color-opacity: $focus-outline-opacity;
$input-btn-focus-color: var(--focus-outline-color);

$form-floating-padding-y: 0.6rem;
$form-floating-height: 3.2rem;
$form-floating-input-padding-t: 1.4rem;
$form-floating-input-padding-b: 0.55rem;
$form-floating-label-transform: scale(0.75) translateY(-0.45rem) translateX(0.25rem);

$pagination-hover-bg: inherit;
$pagination-focus-color: inherit;
$pagination-focus-bg: inherit;
$pagination-focus-box-shadow: none;
$pagination-focus-outline: $focus-outline-width solid var(--focus-outline-color);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1250px
);

$zindex-menu-backdrop: 1035;
$zindex-menubar: 1036;
$zindex-accessible-jump-link: 1037;
$zindex-fancybox-backdrop: 1038;

$backdrop-bg: $white;
$backdrop-opacity: 0.7;

$breadcrumb-divider: quote('>');
