@use '../utilities/mixin/font';

// Hide validation messages by default.

.invalid-feedback {
  @include colorscheme-danger;
  @include font.family-frutiger-medium;
  width: 100%;
  margin-top: 0.75rem;
}

.invalid-tooltip__message {
  display: none;
}

.invalid-tooltip {
  @include colorscheme-black-on-warning;
  display: none;
  position: absolute;
  bottom: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0.25em 0.5em;
  z-index: 1;

  // Show client-side validation messages for fields with errors.

  .is-invalid ~ &,
  .is-invalid--required ~ & > .invalid-tooltip__message--required,
  .is-invalid--elementtype ~ & > .invalid-tooltip__message--elementtype,
  .is-invalid--length ~ & > .invalid-tooltip__message--length,
  .is-invalid--range ~ & > .invalid-tooltip__message--range {
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0.5em;
    border: 0.6em solid transparent;
    border-bottom-width: 0;
    border-top-color: $rose;
  }
}

.form-control,
.form-select {

  &.is-invalid,
  .was-validated &:invalid,
  .was-validated--server-side:not(.was-validated--client-side) & {
    border-color: #{$danger};
    --focus-outline-color: #{$danger};
  }
}

.form-check-label {

  &.is-invalid,
  .was-validated &:invalid,
  .was-validated--server-side & {
    color: #{$danger};
  }
}
