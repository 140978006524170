@use '../utilities/mixin/link';
@use '../utilities/mixin/icon';

// Related links
//
// Markup: ../styleguide/markup/component/icon-and-text-list.html
//
// Styleguide component.icon-and-text-list

.icon-and-text-list {
  padding: 0;
}

.icon-and-text-list__listitem {
  display: block;
  margin: 0  0  0.5 * $spacer;
}

.icon-and-text-list__link {
  @include link.no-line-below;
  @include link.no-box-shadow;
  padding-left: 1.2222222em;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.icon-and-text-list__no-link {
  padding-left: 1.2222222em;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.icon-and-text-list__description {
  vertical-align: top;
  max-width: 100%;
  word-break: break-word;
}

.icon-and-text-list__meta {
  display: inline-block;
}
