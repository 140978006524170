@use '../utilities/mixin/focus';
@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';

.breadcrumb {
  @include colorscheme-primary-on-white;
  font-size: font.$medium-small-font-size;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  display: block;
  list-style: none;

  a {
    @include link.no-line-below;
  }
}

.breadcrumb-item {
  @include font.family-frutiger-medium;
  display: inline;

  &.active {
    @include visually-hidden();
  }

  &:nth-last-child(n + 2) {

    &::after {
      content: '>';
    }
  }
}

.page-link {
  @include link.no-line-below;
}

.accessible-jump-links {
  @include focus.focus-outline-inside;

  > * {
    @include colorscheme-white-on-dark;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.5em;
    z-index: $zindex-accessible-jump-link;
  }
}
