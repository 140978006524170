@use '../utilities/mixin/font';

$contact-list-horizontal-gap: 4.5rem;
$contact-list-vertical-gap:   2.25rem;
$contact-gap:                 1.5rem;

.contact-list {
  display: inline-flex;
  flex-wrap: wrap;
}

.contact-list--horizontal {
  gap: $contact-list-horizontal-gap;
}

.contact-list--vertical {
  gap: $contact-list-vertical-gap;
}

.contact-list__item {

}

.contact-list__item--columnized {
  width: 100%;

  @include media-breakpoint-up('sm') {
    width: calc(50% - #{$contact-list-horizontal-gap});
    flex-grow: 1;
  }
}

.contact__column--leading {
  width: 128px;
  flex-grow: 0;
  flex-shrink: 0;

  .contact-list__item--columnized & {
    width: 180px;
  }
}

.contact__column--following {
  flex-grow: 1;
}

.contact {
  display: flex;
  gap: $contact-gap;
  margin: 0;
  flex-direction: column;

  @include media-breakpoint-up('xl') {

    .contact-list__item--columnized & {
      flex-direction: row;
    }
  }
}

.contact__image {
  border-radius: 50%;
}

.contact__headline {
  @include font.family-frutiger-medium-cond;
  font-size: 1.375rem;
  line-height: 1.45;
  color: $gray-700;
  margin-bottom: 0;
}

.contact__headline--large {
  font-size: 1.725rem;
}

.contact__body {
  line-height: 1.45;
  margin-top: 2.25em;

  > * + * {
    margin-top: 1.125em;
  }
}

.contact__body--flush {
  margin-top: 0;

  > * + * {
    margin-top: 0;
  }
}
