@use '../configuration/variables';
@use '../utilities/mixin/font';
@use '../utilities/mixin/triangle';

.timeline__items {
  @include colorscheme-primary-on-white;
  display: flex;
  padding: 0;
  margin: 0 0 -2.25rem -0.5rem;
  flex-wrap: wrap;
  position: relative;

  &::before {
    content: '';
    background-color: variables.$white;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    z-index: 1;
  }
}

.timeline__item {
  @include font.family-frutiger-medium;
  list-style-type: none;
  flex-grow: 1;
  flex-basis: 8rem;
  position: relative;
  margin-top: 0.8125rem;
  padding: 2.25rem 2rem 2.25rem 0.5rem;
  color: variables.$blue;
  border-top: 0.625rem solid currentColor;

  &::before,
  &::after {
    @include triangle.equilateral;
    content: '';
    position: absolute;
    top: -1.4375rem;
    height: 2.25rem;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    background-color: variables.$white;
    right: -0.55rem;
  }

  &::after {
    background-color: currentColor;
    right: 0;
  }
}

.timeline__item--highlighted {
  color: variables.$hrk;
  --link-hover-background-color: #{$hrk};
}

.timeline__text {
  position: relative;
  z-index: 2;
}

.timeline__link {
  text-decoration-color: currentColor;
}
