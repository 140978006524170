@use '../configuration/variables';
@use '../utilities/mixin/triangle';

@include media-breakpoint-up('md') {

  .diagonal-bar {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: variables.$zindex-triangles;

    &::before {
      @include triangle.bottom-right;
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 100%;
      background-color: inherit;
    }

    &::after {
      @include triangle.bottom-right;
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      height: 100%;
      margin-right: -1px;
      background-color: inherit;
    }
  }

  .diagonal-bar--before {
    background-color: variables.$blue-050;
  }

  .diagonal-bar--after {
    background: variables.$white;
  }

  .diagonal-bar--with-margin {
    margin-top: 10rem;
  }
}
