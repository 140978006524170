@use '../utilities/mixin/link';
@use '../configuration/variables';

.to-top {
  position: sticky;
  z-index: variables.$zindex-to-top;
  bottom: 0;
}

.to-top__inner {
  position: relative;
}

.to-top__link {
  @include colorscheme-white-on-primary;
  --link-hover-background-color: #{variables.$blue-100};
  --link-active-color: #{variables.$white};
  --link-active-background-color: #{variables.$blue-600};
  font-size: 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(90deg);
  box-shadow: 0 0 0 2px variables.$white;
}
