@use '../utilities/offset';

.site-header {
  background-color: $white;
  z-index: $zindex-menubar;
}

.site-header__topbar {
  display: none;
}

.site-header__logo {
  height: max-content;
}

@include media-breakpoint-up('lg') {

  .site-header__topbar {
    @include make-container();
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .site-header__logo {
    height: max-content;
  }
}

@include media-breakpoint-up('xxl') {

  .site-header__topbar {
    max-width: map-get($container-max-widths, 'xxl');
  }

  .site-header__logo {
    margin-left: -105px;
  }
}
