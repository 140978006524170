// Privacy Popup
//
// The Popup we show the user before embedding content from third-party websites (i.e. YouTube videos), GDPR compliance.

.privacy-popup {
  margin: 36px;
  overflow: auto;
  max-width: calc(96vw - 72px);
  max-height: calc(97.2vh - 72px);
}

.privacy-popup--has-iframe {
  position: relative;
  overflow: hidden;

  // This height is derived from the width via the padding of privacy-popup__inner.
  // This technique is used to ensure a fixed aspect ratio.
  max-height: none;
  width: calc(96vw - 72px); // The resulting height is: calc(54vw - 40.5px)
  max-width: calc(172.8vh - 128px); // The resulting max-height is: calc(97.2vh - 72px)
}

.privacy-popup__inner {
  padding: 56.25% 0 0;
}

.privacy-popup__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
