@mixin colorscheme-success {
  color: $success;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$white};
  --link-hover-background-color: #{$success};
  --link-active-color: #{$green-100};
  --link-active-background-color: #{$green-600};
}

@mixin colorscheme-danger {
  color: $danger;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$white};
  --link-hover-background-color: #{$danger};
  --link-active-color: #{$red-100};
  --link-active-background-color: #{$red-600};
}

@mixin colorscheme-dark-on-white {
  color: $dark;
  background: $white;

  --headline-color: #{$gray-700};

  --link-underline-color: #{$primary};
  --link-icon-color: #{$primary};

  --link-hover-color: #{$white};
  --link-hover-background-color: #{$primary};
  --link-active-color: #{$white};
  --link-active-background-color: #{$blue-600};
}

@mixin colorscheme-primary-on-white {
  color: $primary;
  background: $white;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$white};
  --link-hover-background-color: #{$primary};
  --link-active-color: #{$white};
  --link-active-background-color: #{$blue-600};
}

@mixin colorscheme-dark-on-light {
  color: $dark;
  background: $light;

  --headline-color: #{$gray-700};

  --link-underline-color: #{$primary};
  --link-icon-color: #{$primary};

  --link-hover-color: #{$white};
  --link-hover-background-color: #{$primary};
  --link-active-color: #{$white};
  --link-active-background-color: #{$blue-600};
}

@mixin colorscheme-white-on-dark {
  color: $white;
  background: $dark;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$blue};
  --link-hover-background-color: #{$white};
  --link-active-color: #{$blue};
  --link-active-background-color: #{$blue-150};
}

@mixin colorscheme-white-on-secondary {
  color: $white;
  background: $secondary;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$blue};
  --link-hover-background-color: #{$white};
  --link-active-color: #{$blue};
  --link-active-background-color: #{$blue-150};
}

@mixin colorscheme-white-on-primary {
  color: $white;
  background: $primary;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$blue};
  --link-hover-background-color: #{$white};
  --link-active-color: #{$blue};
  --link-active-background-color: #{$blue-150};
}

@mixin colorscheme-white-on-info {
  color: $white;
  background: $info;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$blue};
  --link-hover-background-color: #{$white};
  --link-active-color: #{$blue};
  --link-active-background-color: #{$blue-150};
}

@mixin colorscheme-white-on-success {
  color: $white;
  background: $success;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$success};
  --link-hover-background-color: #{$white};
  --link-active-color: #{$green-600};
  --link-active-background-color: #{$green-100};
}

@mixin colorscheme-white-on-danger {
  color: $white;
  background: $danger;

  --headline-color: currentColor;

  --link-underline-color: currentColor;
  --link-icon-color: currentColor;

  --link-hover-color: #{$danger};
  --link-hover-background-color: #{$white};
  --link-active-color: #{$red-600};
  --link-active-background-color: #{$red-100};
}

@mixin colorscheme-black-on-warning {
  color: $black;
  background: $rose;

  --headline-color: currentColor;

  --focus-outline-color: #{$danger};

  --link-underline-color: #{$danger};
  --link-icon-color: #{$danger};

  --link-hover-color: #{$white};
  --link-hover-background-color: #{$danger};
  --link-active-color: #{$red-100};
  --link-active-background-color: #{$red-600};
}
