.list-normal {
  list-style: none;

  li + li {
    margin-top: 0.5em;
  }

  li::before {
    content: '\2022';
    color: $hrk;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
