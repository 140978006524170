@use '../configuration/variables';
@use '../utilities/mixin/triangle';

// Image Lightbox
//
// Markup: ../styleguide/markup/image/lightbox.html
//
// Styleguide image.lightbox

img,
svg {
  max-width: 100%;
  height: auto;
}

.image-container {
  position: relative;
}

.image-container__triangle {
  @include triangle.top-left;
  position: absolute;
  top: -1.25rem;
  left: -1.25rem;
  height: 100%;
  max-width: calc(50% + 1.25rem); // half of the image container, plus account for offset
  z-index: 0;
  pointer-events: none;
  mix-blend-mode: darken;

  &:not(:where(.image-element--has-triangle &)),
  &:where(.image-gallery__image:nth-of-type(n + 2) &) {
    display: none;
  }

  &::after {
    @include triangle.top-left;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    background: variables.$primary;
  }
}

.image-container__picture {
  position: relative;
}

.image-circled {
  border-radius: 50%;
}
