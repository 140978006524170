@use '../configuration/variables';
@use '../utilities/mixin/focus';
@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';

// Cards
//
// Markup: ../styleguide/markup/component/cards.html
//
// Styleguide component.cards

.cards {
  @include colorscheme-dark-on-white;
  background-color: transparent;
}

.cards__items {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  column-gap: 1.5rem;
  padding: 0;
  margin: 0;
  hyphens: auto;
}

.cards__item--color-primary {
  @include colorscheme-white-on-primary;
  background: transparent;
  --link-hover-color: #{$blue};
  --link-hover-background-color: #{$blue-150};
  --link-active-color: #{$white};
  --link-active-background-color: #{$blue-600};

  :where(.cards__link, .cards__body) {
    background: $primary;
  }
}

.cards__item--color-light {
  @include colorscheme-dark-on-light;
  background: transparent;

  :where(.cards__link, .cards__body) {
    background: $light;
  }
}

.cards__link .cards__body {
  background: inherit;
}

@include media-breakpoint-up('sm') {

  .cards__item {
    flex-basis: calc(50% - 0.75rem);
    display: flex;
    flex-direction: column;
  }
}

@include media-breakpoint-up('md') {

  .cards__item {
    flex-basis: calc(33.333333% - 1rem);
  }

  .cards__item.cards__item-50 {
    flex-basis: calc(50% - 0.75rem);
  }
}


.cards--links {

  .headline {
    margin-bottom: 1rem;
  }

  .cards__items {
    margin-top: 2.5rem;
    row-gap: 4rem;
  }

  .cards__item {
    $clipTopLeft: -1px -1px;
    $clipTopRight: calc(100% + 1px) -1px;
    $clipBottomLeft1: calc(100% + 1px) calc(100% - 2rem);
    $clipBottomLeft2: calc(100% - #{variables.$equilateral-triangle-altitude * 4rem}) calc(100% + 1px);
    $clipBottomRight: -1px calc(100% + 1px);
    clip-path: polygon($clipTopLeft, $clipTopRight, $clipBottomLeft1, $clipBottomLeft2, $clipBottomRight);
  }

  .cards__title {
    padding-right: 1.5rem;
    font-size: 26px;
  }

  .cards__text {
    margin-right: 1.5rem;
  }

  .cards__body {
    padding-bottom: 2rem;
  }
}

.cards__link {
  @include focus.focus-outline-inside;
  @include link.no-line-below;
  @include transition(link.$transition);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  box-shadow: 0 0 0 1px var(--card-frame-color);

  &:hover,
  &:active {
    box-shadow: 0 0 0 1px var(--card-frame-color);

    .image {
      transform: scale(1.1);
    }
  }
}

.cards__image {
  background-color: $blue-050;
  overflow: hidden;

  figure {
    margin: 0;
  }

  .image {
    @include transition(transform 0.2s);
  }
}

.cards__body {
  flex-grow: 1;
  max-height: 100%;

  > .cards__image {
    padding: 1rem 1rem 0;
  }
}

.cards__body--events {
  padding: 0.8rem 0;

  .cards__item--events-in-news-cards & {
    padding: 0.3rem 0;
  }
}

.cards__meta {
  @include font.family-frutiger-light-cond;
  font-size: font.$small-font-size;
  line-height: font.$small-line-height;
  margin: 1rem 1rem -0.6rem;
}

.cards__meta--events {
  @include font.family-frutiger-medium-cond;
  font-size: font.$medium-small-font-size;
  line-height: font.$small-line-height;
  margin: 0 1rem;

  .cards__item--events-in-news-cards & {
    margin: 0 0.3rem;
  }
}

.cards__title {
  @include font.family-frutiger-medium-cond;
  font-size: font.$medium-large-font-size;
  line-height: font.$small-line-height;
  padding: 1rem;
}

.cards__title--events {
  padding: 1rem 1rem 0;

  .cards__item--events-in-news-cards & {
    padding: 1rem 0.3rem 0;
  }
}

.cards__text {
  font-size: font.$medium-small-font-size;
  margin: -0.3rem 1rem 0;
  padding: 0 0 1.2rem;
}

.cards__text--events {
  @include font.family-frutiger-light-cond;
  margin: 0 1rem;
  padding: 0;

  .cards__item--events-in-news-cards & {
    margin: 0 0.3rem;
  }
}

.cards--events,
.cards--news {

  p {
    margin-bottom: 0;
  }
}

.cards__items--events,
.cards__items--news {
  display: inline-grid;
  grid-auto-columns: 1fr;
  width: 100%;
  grid-auto-flow: row dense;
}

.cards__item--news {
  max-height: 450px;
  order: 1;
}

.cards__item--events-in-news-cards {
  padding: 0 0.7rem;

  &.bg-primary {
    --link-hover-background-color: #{$white};
  }

  > :where(:nth-last-child(n + 2)) {
    position: relative;
    margin-bottom: 1px;

    :where(.cards__link)::after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 0.3rem;
      right: 0.3rem;
      border-bottom: 1px solid $gray-300;
    }
  }

  :where(.cards__link) {
    @include link.no-box-shadow;
  }
}

.cards__link--news {
  @include transition(max-height 0.2s);
  max-height: 450px;
}

.cards__link--events {

  .cards__item--events-in-news-cards & {
    margin: 0.5rem 0;
  }
}

.cards__link--has-overflow {
  background: linear-gradient(to top, $gray-200, $white 3em);
}

.cards__link--show-complete {
  background: $white;
  max-height: none;
  z-index: 2;

  &,
  &:hover,
  &:active {
    box-shadow: 0 0 0 1px $primary, 4px 10px 10px 0 change-color($black, $alpha: 0.4);
    z-index: 2;
  }
}

.cards__body--news {
  max-height: 100%;
  clip-path: polygon(-1px -1px, -1px 100%, calc(100% + 1px) 100%, calc(100% + 1px) -1px);
}

@include media-breakpoint-up('sm') {
  // Layout in 2 columns

  .cards__item--events,
  .cards__item--news {

    &:where(:nth-child(2)) {
      grid-column-start: 2;
    }
  }

  .cards__item--events-in-news-cards {
    grid-row: span 2;
    order: 2;
    grid-column-start: 2;
  }

  .cards__item--news {

    &:where(:last-child, :nth-last-child(2), :nth-last-child(3)) {
      order: 3;
    }
  }
}

@include media-breakpoint-up('lg') {
  // Layout in 3 columns

  .cards__item--events,
  .cards__item--news {

    &:where(:nth-child(3):not(:nth-last-child(2))) {
      grid-column-start: 3;
    }
  }

  .cards__item--events-in-news-cards {
    grid-column-start: 3;
  }

  .cards__item--news {

    &:where(:nth-last-child(4), :nth-last-child(5)) {
      order: 3;
    }
  }
}

.cards__pin-icon {
  @include icon.mask-image(icon.$push-pin);
  @include icon.mask-size(80%);
  background-color: $primary;
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    background-color: $white;
    padding: 2.3rem 2rem 0 0;
  }
}

.cards__sizing-button {
  @include icon.mask-image(icon.$add);
  @include icon.mask-size(80%);
  position: relative;
  margin: -1em 0;
  padding: 1em;
  border: none;
  align-self: center;
  border-radius: 50%;
  background-color: $info;
  display: none;

  .cards__link--has-overflow + & {
    display: block;
  }

  .cards__link--show-complete + & {
    @include icon.mask-image(icon.$close);
    z-index: 2;
  }

  &:hover,
  &:active {
    background-color: $primary;
  }

  &:active {
    transform: translateY(2px);
  }

  &::after {
    @include transition(transform 0.2s);
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 2;
    background-color: $white;
  }
}
