@use '../utilities/mixin/font';

.image-with-copyright {
  position: relative;
}

.image-with-copyright__container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: clamp(90%, 5rem, 100%);
  margin: 0 0 10%;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.image-with-copyright__copyright {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  word-wrap: break-word;
  font-size: font.$small-font-size;
  max-height: 100%;
  line-height: 1;
  color: $black;
  background: $white;
  padding: 0.4rem 0.3rem 1.2rem 0.2rem;
  transform: rotate(180deg);
}
