/*!
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

@import url('//fast.fonts.net/t/1.css?apiType=css&projectid=64822624-bdd9-4ab3-9b6b-bf4556f18fa8');

@font-face {
  font-family: Frutiger Next W04 Light Cond;
  src: url('../../fonts/f6bd4877-68a0-4eba-92e9-d07ae4050330.woff2') format('woff2');
}

@font-face {
  font-family: Frutiger Next W04 Medium Cond;
  src: url('../../fonts/3df399c0-a2cf-4ee3-ac56-be22545c8aae.woff2') format('woff2');
}

@font-face {
  font-family: Frutiger Next W01 Light;
  src: url('../../fonts/fa8815a0-4d7b-4eb5-8184-9d9ff16708b2.woff2') format('woff2');
}

@font-face {
  font-family: Frutiger Next W01 Medium;
  src: url('../../fonts/131679ac-c45d-48d4-b4ee-b3cc4cdb6dea.woff2') format('woff2');
}
