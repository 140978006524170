@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';

// Accordion
//
// Markup: ../styleguide/markup/component/accordion.html
//
// Styleguide component.accordion

.accordion-button {
  @include font.regular-text;
  @include transition(padding 0.2s, margin, background-color, background-image, transform);
  @include font.family-frutiger-medium;
  flex-direction: row;
  justify-content: space-between;
  color: $gray-700;
  background: $white;
  padding: 0.75rem 0 0.5rem 1.25rem;

  &::after {
    margin: 0.1rem 0.5rem 0 0;
    transform: translate(-2px, -2px) rotate(0deg);
    align-self: center;
  }

  &:not(.collapsed) {
    box-shadow: none;
    color: $gray-700;
    background: $blue-150;

    &::after {
      transform: translate(-2px, -3px) rotate(-180deg);
    }
  }

  &:hover {
    color: $white;
    background: $primary;

    &::after {
      background-image: escape-svg($accordion-button-white-icon);
    }
  }

  &:active {
    color: $white;
    background: $blue-600;

    &::after {
      background-image: escape-svg($accordion-button-white-icon);
    }
  }
}

.accordion-item {
  border-bottom-width: 1px;

  &:first-of-type {
    border-top-width: 1px;
  }
}
