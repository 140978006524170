@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';
@use '../configuration/variables';

// Filelinks
//
// Styleguide component.filelink

// Filename only
//
// Filesize and description can be added optionally
//
// Markup: ../styleguide/markup/component/filelink-type-0.html
//
// Styleguide component.filelink.type-0

.filelink-list {
  list-style: none;
  padding-left: 0;
}

.filelink-list--type-0 {
  clip-path: polygon(-100% 0, 200% 0, 200% 200%, -100% 200%);

  @include media-breakpoint-up(md) {
    columns: 2 18em;
    column-gap: $ce-margin-small;
  }

  .filelink-list__item {
    display: inline-block;
    width: 100%;
    padding: map-get($spacers, 2)  0;
    margin: (map-get($spacers, 2) * -1)  0  map-get($spacers, 2);

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .filelink__anchor {
    @include link.no-line-below;
  }
}


// Thumbnail and filename
//
// Filesize and description can be added optionally
//
// Markup: ../styleguide/markup/component/filelink-type-0.html
//
// Styleguide component.filelink.type-2

.filelink-list--type-2 {
  margin-bottom: 0;
}

.filelink-list__item--type-2 {
  background-color: variables.$blue-100;
  padding: 1.8125rem;

  &:not(:last-child) {
    margin-bottom: map-get($spacers, 5);
  }

  .filelink__filesize {
    @include font.family-frutiger-medium;
    font-size: font.$small-font-size;
    color: variables.$gray-700;
    margin: 0 0 0.8125rem;
  }
}

.filelink-list__item {
  display: block;

  &::after {
    content: '' !important;
    display: block !important;
    clear: both !important;
  }
}

.filelink__headline {
  @include font.family-frutiger-medium-cond;
  font-size: 1.625rem;
  color: variables.$gray-700;
  margin: -0.25em 0 0.8125rem;
}

.filelink__thumbnail-link {
  background-color: variables.$white;
  min-width: 3rem;
}

.filelink__media {
  float: left;
  margin-right: map-get($spacers, 4);

  > img {
    display: block;
  }
}

.filelink__body {
  overflow: hidden;
  display: block;

  &,
  > :last-child {
    margin-bottom: 0;
  }
}

.filelink__anchor-inner {
  flex-basis: 1em;
  flex-grow: 1;
}

.filelink__filename {
  overflow-wrap: anywhere;
}

