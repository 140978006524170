.modal-header {
  padding: 0;
  border-bottom: none;
}

.modal-title {
  padding: 0 1rem;
}

.modal-close {
  padding: 0;
  border: none;
  background-color: transparent;

  .icon {
    fill: $primary;
  }

  &:hover {
    background-color: $primary;

    .icon {
      fill: $white;
    }
  }
}
