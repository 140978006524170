$transition: color 0.2s, background-color, box-shadow, border, transform;
$space-around: 0.1666667em;

@mixin line-below {
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
  text-underline-offset: 0.3em;
  text-decoration-color: var(--link-underline-color);
}

@mixin no-line-below {
  text-decoration: none;
}

@mixin box-shadow {
  box-shadow: 0 0 0 $space-around transparent;

  &:hover {
    box-shadow: 0 0 0 $space-around var(--link-hover-background-color);
  }

  &:active {
    box-shadow: 0 0 0 $space-around var(--link-active-background-color);
  }
}

@mixin no-box-shadow {
  box-shadow: none;

  &:hover,
  &:active {
    box-shadow: none;
  }
}

@mixin set-hover-colors {

  &:hover {
    --headline-color: currentColor;
    color: var(--link-hover-color);
    background: var(--link-hover-background-color);
  }
}

@mixin set-active-colors {

  &:active {
    --headline-color: currentColor;
    color: var(--link-active-color);
    background: var(--link-active-background-color);
  }
}
