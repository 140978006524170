@use '../../configuration/variables';

@mixin equilateral {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  aspect-ratio: variables.$equilateral-triangle-altitude;
}

@mixin top-left {
  clip-path: polygon(0 0, 100% 0, 0 100%);
  aspect-ratio: variables.$equilateral-triangle-altitude * 2;
}

@mixin top-right {
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  aspect-ratio: variables.$equilateral-triangle-altitude * 2;
}

@mixin bottom-left {
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  aspect-ratio: variables.$equilateral-triangle-altitude * 2;
}

@mixin bottom-right {
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  aspect-ratio: variables.$equilateral-triangle-altitude * 2;
}
