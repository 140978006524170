@use '../utilities/mixin/link';

.list-links {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-links__item {
  font-size: 1rem;
}

.list-links__item + .list-links__item {
  margin-top: 0.5rem;
}

.list-links__anchor {
  @include link.no-line-below;
}

.list-links__divider {
  margin-top: 1.5em;
}
