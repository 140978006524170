@use '../../copyright/bootstrap';

@mixin mask-image($url, $repeat: no-repeat) {

  &::after,
  &::before {
    -webkit-mask-image: url($url);
    mask-image: url($url);
    -webkit-mask-repeat: $repeat;
    mask-repeat: $repeat;
  }
}

@mixin mask-size($size, $position: center) {

  &::after,
  &::before {
    -webkit-mask-size: $size;
    mask-size: $size;
    -webkit-mask-position: $position;
    mask-position: $position;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

$add: '../../../images/svg/icons/add_black_24dp.svg';
$arrow-down: '../../../images/svg/icons/arrow_downward_black_24dp.svg';
$arrow-left: '../../../images/svg/icons/arrow_back_black_24dp.svg';
$arrow-right: '../../../images/svg/icons/arrow_forward_black_24dp.svg';
$arrow-up: '../../../images/svg/icons/arrow_upward_black_24dp.svg';
$check: '../../../images/svg/icons/check_black_24dp.svg';
$close: '../../../images/svg/icons/close_black_24dp.svg';
$download: '../../../images/svg/icons/save_alt_black_24dp.svg';
$external: '../../../images/svg/icons/north_east_black_24dp.svg';
$info: '../../../images/svg/icons/info_black_24dp.svg';
$language: '../../../images/svg/icons/language_black_24dp.svg';
$navigate-before: '../../../images/svg/icons/navigate_before_black_24dp.svg';
$navigate-next: '../../../images/svg/icons/navigate_next_black_24dp.svg';
$mail: '../../../images/svg/icons/mail_black_24dp.svg';
$menu-search: '../../../images/svg/icons/menu_search.svg';
$place: '../../../images/svg/icons/place_black_24dp.svg';
$play: '../../../images/svg/icons/play_arrow_black_24dp.svg';
$play-circle: '../../../images/svg/icons/play_circle_black_24dp.svg';
$print: '../../../images/svg/icons/print_black_24dp.svg';
$pause-circle: '../../../images/svg/icons/pause_circle_black_24dp.svg';
$rss: '../../../images/svg/icons/rss_feed_black_24dp.svg';
$push-pin: '../../../images/svg/icons/push_pin_black_24dp.svg';
$search: '../../../images/svg/icons/search_black_24dp.svg';
$share: '../../../images/svg/icons/share_black_24dp.svg';
$volume: '../../../images/svg/icons/volume_up_black_24dp.svg';
