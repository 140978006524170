@use '../utilities/mixin/font';
@use '../utilities/mixin/link';
@use '../utilities/mixin/triangle';
@use '../configuration/variables';

.site-footer--bottom {
  position: relative;
  padding-top: 8rem;

  @include media-breakpoint-between('md', 'xxl') {
    padding-top: 0;
    padding-left: 10rem;
  }

  &::after {
    @include triangle.bottom-right;
    content: '';
    background: variables.$blue-100;
    display: block;
    position: absolute;
    width: clamp(45vw, 30em, 80vw);
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: variables.$zindex-triangles;
   }
}

.site-footer__functions {
  @include colorscheme-dark-on-white;
}

.site-footer__inner {
  position: relative;
  z-index: 1;
  padding-bottom: 8rem;

  @include media-breakpoint-up('xxl') {
    padding-bottom: 6.25rem;
  }
}

.site-footer__triangle {
  position: absolute;
  width: #{variables.$equilateral-triangle-altitude * 9rem};
  left: 0;
  top: -11rem;
  height: 9rem;
  overflow: hidden;
  pointer-events: none;
  z-index: variables.$zindex-triangles;

  @include media-breakpoint-up('md') {
    width: 50vw;
    height: auto;
    left: auto;
    right: calc(100% + 1.5rem - var(--bs-gutter-x, 0.75rem));
    top: #{variables.$reciprocal-altitude * -0.5 * 50}vw;
    bottom: 0;
  }

  &::before,
  &::after {
    @include triangle.equilateral;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
  }
}

.site-footer__triangle--left-above {
  margin-top: 1.5rem;

  @include media-breakpoint-up('md') {
    margin-top: 2.5rem;
  }

  &::before {
    content: '';
    background: variables.$blue-200;
  }
}

.site-footer__triangle--left {
  margin-top: 3rem;

  @include media-breakpoint-up('md') {
    margin-top: 5rem;
  }

  &::before {
    content: '';
    background: variables.$blue-150;
  }

  &::after {
    content: '';
    background: variables.$hrk;
    right: #{variables.$equilateral-triangle-altitude * 2 * 1.5rem};
  }

  @include media-breakpoint-up('md') {

    &::after {
      right: #{variables.$equilateral-triangle-altitude * 2 * 2.5rem};
    }
  }
}

.site-footer__logo {
  display: block;
  margin-left: 0 !important;
}

.footer-functions__inner {
  height: 3.125rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: end;
}

.footer-functions__anchor {
  @include font.family-frutiger-medium-cond;
  color: variables.$primary;
  @include link.no-line-below;

  &::before,
  &::after {
    background-color: variables.$primary;
  }
}

.social-links {
  margin-bottom: 0.5rem;
}

.site-footer__project-name {
  @include font.family-frutiger-medium;
}

.site-footer__funding-info {
  font-size: font.$medium-small-font-size;
  padding: 2rem 0;
}

.social-links__anchor {
  @include link.no-box-shadow;
  --link-hover-background-color: #{variables.$blue-150};
  --link-active-background-color: #{variables.$blue-600};
  width: 3rem;
  height: 3rem;
  background-color: variables.$primary;
  border-radius: 50px;
  display: inline-block;
  margin-right: 0.5rem;
}

.social-links__anchor + .social-links__anchor {
  margin-left: 0.5em;
}

.social-links__icon {
  @include transition(fill 0.2s);
  fill: variables.$white;

  &:hover {
    fill: variables.$blue;
  }

  &:active {
    fill: variables.$white;
  }
}
