@use 'sass:math';

.frame-type-form_formframework > .frame-content {
  padding-bottom: 2rem;
}

.frame-type-text,
.frame-type-textpic,
.frame-type-textmedia {

  p,
  ul,
  ol {
    margin-bottom: 1.5rem;
  }

  h2,
  h3,
  h4,
  h5 {

    &:not(:where(header *, :first-child, h2 + *, h3 + *, h4 + *, h5 + *)) {
      margin-top: 2.5rem;
    }
  }

  .btn {
    margin: 0.5rem;
  }
}

.frame-space-after-large {
  padding-bottom: 2rem;
}

.frame-space-after-extra-large {
  padding-bottom: 4rem;
}

.wide-content {
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);

  .frame {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);

    .frame {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include media-breakpoint-up('md') {

    .frame-in-diagonal-bar {
      margin-left: math.div(100%, 12);
      margin-right: math.div(300%, 12);
    }
  }

  @include media-breakpoint-up('lg') {

    .frame-default {
      margin-right: math.div(400%, 12);
    }

    .frame-center {
      margin-left: math.div(200%, 12);
      margin-right: math.div(200%, 12);
    }

    .frame-right {
      margin-left: math.div(400%, 12);
    }

    .frame-in-diagonal-bar {
      margin-left: math.div(100%, 12);
      margin-right: math.div(600%, 12);
    }
  }

  @include media-breakpoint-up('xl') {

    .frame-default {
      margin-right: math.div(500%, 12);
    }

    .frame-center {
      margin-left: math.div(250%, 12);
      margin-right: math.div(250%, 12);
    }

    .frame-right {
      margin-left: math.div(500%, 12);
    }
  }
}
