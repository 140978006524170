@use '../configuration/variables';
@use '../utilities/mixin/triangle';

.site-stage {
  position: relative;
  background-color: variables.$blue-100;

  img,
  svg {
    width: 100%; // don't set globally to avoid breaking stuff
  }

  @include media-breakpoint-up('lg') {

    .svg,
    svg {
      width: auto;
      max-width: 685px;
      height: 385px;
    }

    &::after {
      @include triangle.bottom-right;
      background-color: $hrk;
      content: '';
      position: absolute;
      right: 0;
      left: 85vw;
      bottom: 0;
      pointer-events: none;
      z-index: #{variables.$zindex-triangles + 2};
    }
  }

  @include media-breakpoint-up('xxl') {

    &::after {
      left: max(85vw, 50vw + 600px);
    }

    &::before {
      content: '';
      position: absolute;
      background-color: variables.$white;
      z-index: #{variables.$zindex-triangles + 2};
      left: calc(50vw + 585px);
      right: 0;
      bottom: 100%;
      height: 100vh;
      pointer-events: none;
    }

    figure {
      max-width: none;
    }
  }
}
