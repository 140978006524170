@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';

// Links
//
// Markup: ../styleguide/markup/text/links.hbs
//
// icon-link--beside-text-right - show the icon on the right of the text
// icon-link--beside-text-left - show the icon on the left of the text
// icon-link--above-text - show the icon above the text
// icon-link--hide-text - show only the icon and hide the text
//
// Styleguide text.links

$icon-height: 1.1111111em;
$icon-width: 0.9444444em;
$space-between-icon-and-text: 0.2777778em;

a {
  @include link.set-hover-colors;
  @include link.set-active-colors;
  @include link.box-shadow;
  @include link.line-below;
  @include transition(link.$transition);
  box-decoration-break: clone;

  &:hover,
  &:active {
    @include link.no-line-below;
  }
}

.link-box {
  @include colorscheme-white-on-primary;
  @include link.no-line-below;
  @include link.no-box-shadow;
  display: inline-block;
  padding: 0.25em 0.5em;
  margin: 0.25em;
}

.language-link {
  @include link.no-line-below;
  @include font.family-frutiger-medium-cond;
  color: $primary;
}

.image-link {
  @include link.no-box-shadow;
  display: inline-block;
  position: relative;
  color: $dark;
  background: transparent;

  &::after {
    @include transition(opacity 0.2s);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $primary;
    mix-blend-mode: darken;
    opacity: 0;
  }

  &:hover,
  &:active,
  &[data-lightbox-open] {
    --focus-outline-color: #{$primary};
    color: $dark;
    background: $white;

    &::after {
      opacity: 0.5;
    }
  }

  figure > & {
    vertical-align: bottom;
  }
}

.image-link__icon {
  @include transition(background-color 0.2s);
  @include icon.mask-size(50%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  height: 3rem;
  width: 3rem;
  z-index: 1;
  background: $primary;
  border-radius: 100%;

  &::after {
    @include transition(background-color 0.2s);
    content: '';
    position: absolute;
    background: $white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .image-link:hover & {
    background: $white;

    &::after {
      background: $primary;
    }
  }

  .image-link:active & {
    background: $blue-600;

    &::after {
      background: $white;
    }
  }
}

.image-link__icon--download {
  @include icon.mask-image(icon.$download);
}

.video-link {
  @include link.no-box-shadow;
  position: relative;
  color: $dark;
  background: $white;
  display: block;
}

.video-link__icon {
  @include icon.mask-image(icon.$play);
  @include icon.mask-size(70%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  height: 5rem;
  width: 5rem;
  z-index: 1;
  background-color: $primary;
  border-radius: 100%;

  &::after {
    @include transition(background-color 0.2s);
    content: '';
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .video-link:hover & {
    background-color: $blue-150;

    &::after {
      background-color: $primary;
    }
  }

  .video-link:active & {
    background-color: $blue-600;

    &::after {
      background-color: $white;
    }
  }
}

.icon-link {
  @include icon.mask-size($icon-height);

  &::before,
  &::after {
    @include transition(top 0.2s, left, background-color);
    background-color: var(--link-icon-color);
    width: $icon-width;
  }

  &:not(:where(.icon-link--beside-text-left, .icon-link--beside-text-right, .icon-link--hide-text, .icon-link--above-text)) {
    @include link.no-box-shadow;
    margin-left: $icon-width;
    box-decoration-break: slice;
    padding: link.$space-around;
    position: relative;

    &::before {
      content: '';
      margin-left: link.$space-around - $icon-width - $space-between-icon-and-text;
      margin-top: link.$space-around;
      position: absolute;
      height: $icon-height;
      top: 0;
      left: 0;
    }
  }
}

.icon-link--beside-text-left,
.icon-link--beside-text-right {
  display: flex;

  &::before,
  &::after {
    display: block;
    height: $icon-height;
    transform: translateY(15%);
    position: relative;
  }

  &:hover,
  &:active {

    &::before,
    &::after {
      background: currentColor;
    }
  }
}

.icon-link--beside-text-left {
  padding-left: $icon-width + $space-between-icon-and-text;

  &::before {
    content: '';
    margin-left: -$icon-width - $space-between-icon-and-text;
    margin-right: $space-between-icon-and-text;
    position: relative;
  }
}

.icon-link--beside-text-right {
  padding-right: $icon-width + $space-between-icon-and-text;

  &::after {
    content: '';
    margin-right: -$icon-width - $space-between-icon-and-text;
    margin-left: $space-between-icon-and-text;
  }
}

.icon-link--above-text {
  @include icon.mask-size(contain);
  display: inline-block;
  padding: 0.5em;

  &::before {
    content: '';
    display: block;
    padding-top: #{1.5 * $icon-height};
    min-width: #{1.5 * $icon-width};
    width: auto;
  }

  &:hover,
  &:active {

    &::before {
      background: currentColor;
    }
  }
}

.icon-link--hide-text {
  @include link.no-line-below;
  @include icon.mask-size(90%);
  display: inline-block;
  width: 3em;
  height: 3em;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: -80%;

  &::before {
    content: '';
    min-width: 100%;
    min-height: 100%;
    display: inline-block;
  }

  &:hover,
  &:active {

    &::before {
      background: currentColor;
    }
  }
}

.icon-link--arrow-down {
  @include icon.mask-image(icon.$arrow-down);
}

.icon-link--arrow-left {
  @include icon.mask-image(icon.$arrow-left);
}

.icon-link--arrow-right,
.icon-link--internal {
  @include icon.mask-image(icon.$arrow-right);
}

.icon-link--arrow-up {
  @include icon.mask-image(icon.$arrow-up);
}

.icon-link--check {
  @include icon.mask-image(icon.$check);
}

.icon-link--close {
  @include icon.mask-image(icon.$close);
}

.icon-link--download {
  @include icon.mask-image(icon.$download);

  &::before,
  &::after {
    top: 0.0555556em;
  }
}

.icon-link--external {
  @include icon.mask-image(icon.$external);
}

.icon-link--navigate-before {
  @include icon.mask-image(icon.$navigate-before);
}

.icon-link--navigate-next {
  @include icon.mask-image(icon.$navigate-next);
}

.icon-link--mail {
  @include icon.mask-image(icon.$mail);
}

.icon-link--menu {
  @include icon.mask-image(icon.$menu-search);

  &[aria-expanded='true'] {
    @include icon.mask-image(icon.$close);
  }
}

.icon-link--place {
  @include icon.mask-image(icon.$place);
}

.icon-link--play {
  @include icon.mask-image(icon.$play-circle);
}

.icon-link--print {
  @include icon.mask-image(icon.$print);

  &::before {
    top: 0.1875em;
    background-color: currentColor;
  }
}

.icon-link--pause {
  @include icon.mask-image(icon.$pause-circle);
}

.icon-link--share {
  @include icon.mask-image(icon.$share);

  &::before {
    top: 0.125em;
    background-color: currentColor;
  }
}

.icon-link--rss {
  @include icon.mask-image(icon.$rss);
}

.icon-link--language {
  @include icon.mask-image(icon.$language);

  &::before,
  &::after {
    top: 0.0555556em;
  }
}
