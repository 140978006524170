.image-element__image-in-text {

  @include media-breakpoint-up('sm') {
    max-width: 33.3333333%;
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    box-sizing: border-box;
  }
}

.image-element--has-triangle {

  .image-element__image-in-text {

    @include media-breakpoint-up('sm') {
      margin-left: 1.75rem;
    }
  }

  .image-element__text-beside-image--image-right {

    @include media-breakpoint-up('md') {
      padding-right: 1.75rem;
    }
  }
}
