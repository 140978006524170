@use '../utilities/mixin/font';
@use '../utilities/mixin/link';

.navigation-meta {
  margin-top: 1.25rem;
}

.navigation-meta__list {
  margin-bottom: 0;
  padding-left: 0;
}

.navigation-meta__list-item {
  display: inline-block;
}

.navigation-meta__list-item + .navigation-meta__list-item {
  margin-left: 1rem;
}

.navigation-meta__anchor {
  @include font.family-frutiger-medium-cond;
  font-size: 1rem;
  @include link.no-line-below;
}

.navigation-meta__icon {

  &.icon {
    width: 1.375rem;
    height: 1.375rem;
    margin-bottom: 0.1875em;
  }
}
