@use '../utilities/mixin/font';
@use '../utilities/mixin/triangle';
@use '../configuration/variables';

.testimonial {
  position: relative;

  &::after {
    @include triangle.top-left;
    content: '';
    position: relative;
    display: block;
    top: 100%;
    left: 1.25rem;
    width: 3rem;
    background: variables.$blue-150;
  }
}

.testimonial__content {
  background: variables.$blue-150;
  padding: 0.25rem;
}

.testimonial__body {
  margin: 1.5rem 1rem 2rem;
}

.testimonial__quote {
  @include font.family-frutiger-light;
  font-size: font.$large-font-size;
  margin: -0.25em 0 0;
}

.testimonial__name {
  @include font.family-frutiger-medium;
  margin-top: 1rem;
}

.testimonial__image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 0.5rem 0 auto;
  overflow: hidden;
}

@include media-breakpoint-up('sm') {

  .testimonial__body {
    display: flex;
    justify-content: space-between;
  }

  .testimonial__left {
    flex-basis: 160px;
    flex-grow: 1;
  }

  .testimonial__right {
    flex-basis: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0.5rem 0 3rem;
  }
}
