// Input and select fields
//
// Markup: ../styleguide/markup/form/input-select.html
//
// Styleguide form.input-select

// Check boxes and radio buttons
//
// Markup: ../styleguide/markup/form/check-radio.html
//
// Styleguide form.check-radio

.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;

  .form-check-input {
    float: left;
    margin-left: $form-check-padding-start * -1;
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: ($line-height-base - $form-check-input-width) * 0.5; // line-height minus check height
  vertical-align: top;
}

.form-check-label {
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}
