$frutiger-light: 'Frutiger Next W01 Light';
$frutiger-medium: 'Frutiger Next W01 Medium';
$frutiger-medium-cond: 'Frutiger Next W04 Medium Cond';
$frutiger-light-cond: 'Frutiger Next W04 Light Cond';

$regular-font-size: 1.125rem;
$large-font-size: 1.25rem;
$small-font-size: 0.9375rem;
$medium-large-font-size: 1.1875rem;
$medium-small-font-size: 1rem;

$regular-line-height: 1.5;
$small-line-height: 1.25;
$large-line-height: 2;

@mixin family-frutiger-light {
  font-family: $frutiger-light, sans-serif;
  font-weight: 300;
}

@mixin family-frutiger-medium {
  font-family: $frutiger-medium, sans-serif;
  font-weight: 300;
}

@mixin family-frutiger-medium-cond {
  font-family: $frutiger-medium-cond, sans-serif;
  font-weight: 300;
}

@mixin family-frutiger-light-cond {
  font-family: $frutiger-light-cond, sans-serif;
  font-weight: 300;
}

@mixin regular-text {
  @include family-frutiger-light;
  font-size: $regular-font-size;
  line-height: $regular-line-height;
}
