// Background and text colors
//
// Markup: ../styleguide/markup/color/background-text.html
//
// Styleguide color.background-text

// Override Bootstrap color values

.bg-white {
  @include colorscheme-dark-on-white;
}

.bg-light {
  @include colorscheme-dark-on-light;
}

.bg-dark {
  @include colorscheme-white-on-dark;
}

.bg-secondary {
  @include colorscheme-white-on-secondary;
}

.bg-primary {
  @include colorscheme-white-on-primary;
}

.bg-info {
  @include colorscheme-white-on-info;
}

.bg-success {
  @include colorscheme-white-on-success;
}

.bg-danger {
  @include colorscheme-white-on-danger;
}

.bg-warning {
  @include colorscheme-black-on-warning;
}

.link-success {
  @include colorscheme-success;
}

.link-danger {
  @include colorscheme-danger;
}

.link-light,
.link-dark,
.link-secondary,
.link-primary,
.link-info,
.link-success,
.link-danger {
  --link-underline-color: currentColor;
  --link-icon-color: currentColor;
}
