@use '../configuration/variables';
@use '../utilities/mixin/link';

.news-list__item {
  padding-bottom: 0.5rem;
  border-bottom: 2px solid variables.$blue-200;

  + .news-list__item {
    margin-top: 1.5rem;
  }

  + .pagination {
    margin-top: 4.5rem;
  }
}

.news-list__time {
  font-size: 1rem;
}

.news-list__headline-link {
  @include link.no-line-below;
  display: block;
  font-size: 1.625rem;
}

.news-list__image-link {
  margin-bottom: 1.5rem;

  &::after {
    z-index: 1;
  }
}

.news-list__image {
  margin: 0;
}
