@use '../utilities/mixin/font';

// Default table
//
// Markup: ../styleguide/markup/table/default.html
//
// Styleguide table.default

// Striped table
//
// Markup: ../styleguide/markup/table/striped.html
//
// Styleguide table.striped

// Override Bootstrap table markup to show headers in a different color

.table {
  --#{$variable-prefix}table-header-color: #{$table-header-color};
  --#{$variable-prefix}table-header-bg: #{$table-header-bg};

  th {
    @include font.family-frutiger-medium-cond;

    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-header-bg);
    color: var(--#{$variable-prefix}table-header-color);
  }

  > :not(:first-child) {
    border-top: 0;
  }
}

@include media-breakpoint-down(sm) {
  // Use a responsive table design on smart phones

  .table-responsive__cell-heading {
    display: inline;
  }

  .table-responsive__cell {
    display: block;
    border-bottom: none;
  }

  .table-responsive__head,
  .table-responsive__cell--hidden {
    @include visually-hidden();
  }
}

@include media-breakpoint-up(sm) {
  // Hide responsive cell headings in other viewports

  .table-responsive__cell-heading {
    display: none;
    visibility: hidden;
  }

  // Highlight table rows on hover
  // Also highlight table columns if the header cell is hovered

  .table-responsive__cell--highlighted,
  .table-responsive__row:hover > * {
    --#{$variable-prefix}table-header-color: #{$table-hover-color};
    --#{$variable-prefix}table-header-bg: #{$table-hover-bg};
    --#{$variable-prefix}table-accent-bg: #{$table-hover-bg};
    color: $table-hover-color;
  }
}
