@use '../utilities/mixin/font';

// Headlines
//
// Markup: ../styleguide/markup/text/headline.html
//
// Styleguide text.headline

h1,
.h1 {
  color: $primary;
}

h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--headline-color);
}

.subheader {
  margin-top: -1.5rem;
}

.eyebrow {
  font-family: font.$frutiger-medium-cond;
  font-size: 1.125rem;
  color: var(--headline-color);
}
