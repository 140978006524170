@use '../configuration/variables';
@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';
@use '../utilities/mixin/triangle';

.search-results {
  padding: 0;
  margin: 0 0 1.5rem;
}

.search-results__item {
  display: block;
  counter-increment: list-item 1;
  margin: 1.5rem 0 0;
  padding: 0;
}

.search-results__link {
  @include link.no-line-below;
  @include link.no-box-shadow;
  @include colorscheme-dark-on-light;
  background-color: variables.$blue-100;
  display: flex;
  align-items: first baseline;
  padding: 1.25rem;

  &::before {
    font-size: 1.625rem;
    font-family: font.$frutiger-medium-cond;
    color: var(--headline-color);
    content: counter(list-item) '.';
    padding: 0 0.5em 0 0;
  }

  &:active:hover {
    transform: translateY(2px);
  }
}

.search-results__meta {
  font-size: font.$small-font-size;
  margin: 1rem 0 0;
}

.search-results__meta-item:nth-child(n + 2) {

  &::before {
    content: ' • ';
  }
}

.search-results__title {
  font-size: 1.625rem;
  font-family: font.$frutiger-medium-cond;
  margin-bottom: 0;
}

.search-results__content {
  margin: 1rem 0 0;

  .search-results__meta + & {
    margin: 0.5rem 0 0;
  }
}

.search-dropdown {
  display: flex;
}

.search-dropdown__toggle {
  @include icon.mask-size(clamp(21px, 7.5vw - 53.4px, 27px));
  padding: 0 0 0 60px;
  min-height: 60px;
  height: auto;

  &[aria-expanded='true'] {
    @include icon.mask-image(icon.$close);
  }

  @include media-breakpoint-up('xl') {
    @include icon.mask-size(27px);
  }
}

.mobile-menu__search,
.search-dropdown__menu {
  padding: 0;
  border: 2px solid variables.$gray-500;
  min-width: 20em;

  button,
  input {
    position: relative;
    border: none;
  }

  button,
  button:focus {
    color: variables.$secondary;
    background: variables.$white;
  }

  button:hover {
    color: variables.$white;
    background-color: variables.$blue;
  }

  button:active {
    color: variables.$white;
    background-color: variables.$blue-600;
  }

  :focus,
  :focus + label {
    z-index: 1;
  }
}

@include media-breakpoint-up('lg') {

  .search-dropdown__toggle {
    @include colorscheme-primary-on-white;
  }
}

.search-form-for-results-list {
  @include colorscheme-dark-on-light;
  background-color: variables.$blue-100;
  overflow: hidden;
  position: relative;

  &::after {
    @include triangle.bottom-right;
    background-color: variables.$hrk;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 15%;
    pointer-events: none;
  }

  @include media-breakpoint-up('sm') {

    &::after {
      height: 25%;
    }
  }

  @include media-breakpoint-up('md') {

    &::after {
      height: 50%;
    }
  }
}

.search-form-for-results-list__content {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &::after {
    @include triangle.bottom-right;
    background-color: variables.$blue-200;
    content: '';
    position: absolute;
    bottom: 0;
    width: 100vw;
    left: calc(100% - 8rem);
    pointer-events: none;
  }

  @include media-breakpoint-up('lg') {

    &::after {
      left: 100%;
    }
  }

  button {
    color: variables.$white;
    background-color: variables.$blue;

    &:hover {
      color: variables.$blue;
      background-color: variables.$white;
    }

    &:active {
      color: variables.$white;
      background-color: variables.$blue-600;
    }
  }
}
