@use '../utilities/mixin/font';
@use '../utilities/mixin/link';

.pagination {
  @include colorscheme-primary-on-white;
  @include list-unstyled();
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link {
  @include colorscheme-primary-on-white;
  @include link.no-line-below;
  @include link.no-box-shadow;
  display: block;
  width: 2rem;
  text-align: center;

  .active > & {
    @include colorscheme-dark-on-white;
    font-family: font.$frutiger-medium;
  }

  &.icon-link {
    --link-icon-color: #{$dark};
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }
}
