@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';

// Buttons
//
// Markup: ../styleguide/markup/form/button.hbs
//
// .btn--size-large - Large variant
//
// Styleguide form.button

@mixin
btn-border-drawing(
  $color: var(--secondaryButtonBorderColor),
  $hover: var(--secondaryButtonBorderColorHover),
  $width: 3px, $vertical: top,
  $horizontal: left,
  $duration: 0.3s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.btn {
  @include link.no-line-below;
  @include link.no-box-shadow;
  @include transition(link.$transition);
  @include font.family-frutiger-medium-cond;
  border: none;
  padding: 0.363em 1.09em;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .btn-check:focus + &,
  &:focus,
  &:active:focus {
    box-shadow: $btn-focus-box-shadow;
  }
}

.btn--size-large {
  font-size: 1.375rem;
  line-height: 1.27;
  letter-spacing: 0.045em;
  padding: 0.551em 1.278em;
}

.btn-primary {
  overflow: hidden;
  position: relative;

  .btn-check:focus + &,
  &:focus,
  &:active:focus {
    box-shadow: $btn-focus-box-shadow;
  }

  &:focus {
    background-color: $primary;
  }

  &:active {
    color: $white;
    background-color: $blue-600;
  }

  &::after {
    background: $blue-150;
    content: '';
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -60px;
    transform: rotate(0);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
}

.btn-primary:hover {
  color: $blue-600;

  &::after {
    width: 100%;
    background: $blue-150;
    left: 0;
    transform: rotate(0);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.btn-outline-primary,
.btn-outline-secondary {
  --secondaryButtonBorderColor: #30567f;
  --secondaryButtonBorderColorHover: #e0e6ec;
  @include btn-border-drawing();

  &:hover {
    background: transparent;
    color: $blue-600;
  }
}

.bg-primary {

  .btn-outline-primary,
  .btn-outline-secondary {
    --secondaryButtonBorderColor: #ffffff;
    --secondaryButtonBorderColorHover: #e0e6ec;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}


// Special case for links that are styled as buttons (currently used in the main navigation)

.btn-link {
  @include link.line-below;
  @include link.set-hover-colors;
  @include link.set-active-colors;
  display: inline;
  padding: 0;

  &:hover,
  &:active {
    @include link.no-line-below;

    &::before {
      animation: none !important;
    }
  }

  &:focus,
  &:active:focus {
    box-shadow: none;
    outline: $focus-outline-width solid var(--focus-outline-color);
  }
}

// Special buttonstyles

.btn-info {

  &:focus {
    background-color: $info;
  }

  &:hover {
    color: $info;
    background-color: $blue-150;
  }

  &:active {
    color: $white;
    background-color: $blue-600;
  }
}

.btn-danger {

  &:focus {
    background-color: $danger;
  }

  &:hover {
    color: $danger;
    background-color: $yellow;
  }

  &:active {
    color: $red-100;
    background-color: $red-600;
  }
}

.btn--search {
  @include icon.mask-image(icon.$search);
  @include icon.mask-size(80%);
  padding: 0 0 0 3.2rem;
  width: 0;
  min-height: 3.2rem;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  &:hover {
    color: $white;
  }

  &::before {
    @include transition(top 0.2s);
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: currentColor;
  }
}
