@use '../configuration/variables';
@use '../utilities/mixin/triangle';

@include media-breakpoint-up('md') {

  .sidebar {

    .diagonal-bar + & {
      padding-top: 3rem;
      margin-bottom: 6rem;
    }
  }
}

@include media-breakpoint-up('lg') {

  .sidebar {
    min-height: 15vw;

    aside {
      padding: 0 1.5rem;
      border-left: 1px solid variables.$border-color;
    }

    .diagonal-bar + & {
      padding-top: 3rem;
      margin-bottom: calc(#{variables.$reciprocal-altitude * 50%} - #{variables.$reciprocal-altitude * 0.5 * 1.5rem});
    }
  }

  .sidebar--has-header-image {
    padding-top: 3rem;

    &::before {
      @include triangle.bottom-right;
      background-color: variables.$blue-200;
      content: '';
      position: absolute;
      height: 30vw;
      margin-top: calc(-30vw - 3rem);
      z-index: #{variables.$zindex-triangles + 1};
      pointer-events: none;
    }
  }

  .sidebar--has-no-header-image {

    .diagonal-bar + & {
      margin-top: 10rem;
    }
  }
}

