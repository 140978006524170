@use '../utilities/mixin/link';

.logo__img {
  display: block;
  width: 326px;
  height: auto;

  @include media-breakpoint-only('lg') {
    width: 280px;
  }
}

.logo {
  --link-hover-background-color: transparent;

  &:active {
    box-shadow: none;
    background-color: transparent;
  }
}
