@use '../utilities/mixin/focus';
@use '../utilities/mixin/font';
@use '../utilities/mixin/link';
@use '../utilities/mixin/icon';
@use '../utilities/offset';

.site-menu {
  width: 100%;
  background-color: change-color($white, $alpha: 0);
  z-index: $zindex-menubar;
  transform: translateX(0);
  padding: 2rem 0 0;
  margin: 0 0 2rem;
}

.site-menu--backdrop-open {
  background-color: $white;
}

.site-menu__backdrop {
  @include transition(opacity 0.2s);
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-menu-backdrop;
  width: 100vw;
  height: 100vh;
  background-color: $backdrop-bg;
  opacity: $backdrop-opacity;

  &[data-is-visible='about-to-open'],
  &[data-is-visible='closing'] {
    opacity: 0;
  }

  &[data-is-visible='open'] {
    cursor: pointer;
  }

  &:not([data-is-visible='open']) {
    pointer-events: none;
  }

  &:not([data-is-visible]) {
    display: none;
  }
}

.menubar {
  @include focus.focus-outline-inside;
  @include font.family-frutiger-medium-cond;
  @include make-container();
  display: flex;
  justify-content: flex-end;
  min-height: 60px;
  max-height: 100vh;

  @include media-breakpoint-up('xxl') {
    max-width: map-get($container-max-widths, 'xxl');
  }
}

.menubar__brand {
  display: flex;
  width: auto;
  height: 100%;
  flex-grow: 1;
  margin-right: 0.5rem;

  @include media-breakpoint-up('xxl') {
    display: flex;
    width: auto;
    top: 0;
  }
}

.menubar__logo {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.menubar__button {
  width: 60px;
  height: auto;
  margin-right: -0.75rem;
  order: 1;
  margin-left: 2rem;

  @include media-breakpoint-up('lg') {
    display: none;
    visibility: hidden;
  }
}

.menu {
  display: none;

  @include media-breakpoint-up('lg') {
    @include font.family-frutiger-medium-cond;
    --link-icon-color: currentColor;
    --link-hover-color: #{$white};
    --link-hover-background-color: #{$blue};
    --link-active-color: #{$white};
    --link-active-background-color: #{$blue-600};
    display: flex;
    flex-direction: column;
    flex-basis: content;
    flex-grow: 5;
    align-self: center;
    align-items: stretch;
  }
}

.menu__inner {
  display: flex;
  flex-direction: row;
  flex-basis: content;
  background: $white;
  height: 100%;
  flex-grow: 1;
}

.menu__items {
  @include colorscheme-primary-on-white;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-basis: content;
  align-items: stretch;
  list-style: none;
}

.menu__item {
  display: flex;
  flex-grow: 1;
  width: auto;
  align-items: stretch;
}

.menu__item--search {
  flex-grow: 0;
  margin-right: clamp(-21px, 2.5vw - 45.8px, -19px);
}

.menu__item-inner {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-grow: 1;
}

.menu__link {
  @include link.no-line-below;
  @include link.no-box-shadow;
  @include transition(color 0.15s ease-in-out, background-color 0.15s ease-in-out);
  @include link.set-hover-colors;
  flex-basis: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  white-space: normal;
  padding: 0.5em;
  font-size: clamp(1.125rem, 5vw - 1.975rem, 1.375rem);
}

.menu__opening-button {
  @include transition(color 0.15s ease-in-out, background-color 0.15s ease-in-out);
  @include link.no-line-below;
  @include link.set-hover-colors;
  @include link.set-active-colors;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  white-space: normal;
  padding: 0.5em;
  width: auto;
  hyphens: auto;
  font-size: clamp(1.125rem, 5vw - 1.975rem, 1.375rem);

  &[aria-expanded='true'] {
    @include link.set-active-colors;
    color: $white;
    background-color: $primary;
  }
}

.menu__active-section {
  background-color: $blue-100;
}

.submenu {
  @include colorscheme-white-on-primary;
  @include font.family-frutiger-medium-cond;
  border: none;
}

.submenu--level2 {
  padding: 0.5rem 0 0.6rem;
  box-shadow: 0 -2px 0 $primary;
  width: max-content;
  max-width: 30rem;
  min-width: max(12rem, 100%);
  border: none;
}

.submenu__items {
  list-style-type: none;
  padding: 0 0 0 1rem;
}

.submenu__items--level2 {
  padding: 0;
}

.submenu__link {
  @include link.no-line-below;
  @include link.no-box-shadow;
  display: block;
  padding: 0.4rem 1.5rem;
  font-size: font.$medium-small-font-size;
}


.mobile-menu {
  @include colorscheme-primary-on-white;
  @include font.family-frutiger-medium-cond;
  --link-hover-color: #{$primary};
  --link-hover-background-color: #{$blue-100};
  --link-active-color: #{$primary};
  --link-active-background-color: #{$blue-150};

  @media (hover: none) {
    --link-hover-background-color: #{$white};
  }

  @include media-breakpoint-up('lg') {
    display: none;
    visibility: hidden;
  }
}

.mobile-menu--level1 {
  padding: 1rem 0.2rem 0;
}

.mobile-menu__opening-button {
  @include icon.mask-size(200%);
  @include link.no-box-shadow;
  @include link.no-line-below;
  text-align: left;
  width: 100%;
  padding: 0.6rem;
  border-top: 2px solid $white;
  justify-content: space-between;
  align-items: center;

  &::after {
    margin: 0;
    transform: rotate(90deg);
  }

  &[aria-expanded='true'] {

    &::after {
      transform: rotate(270deg);
    }
  }
}

.mobile-menu__items {
  background-color: $blue-050;
  list-style-type: none;
  padding: 0 0 0 1rem;
}

.mobile-menu__items--level1 {
  @include colorscheme-white-on-primary;
  --link-hover-color: #{$white};
  --link-hover-background-color: #{$blue-600};
  --link-active-color: #{$white};
  --link-active-background-color: #{$blue-700};
  padding: 0;

  @media (hover: none) {
    --link-hover-background-color: #{$primary};
  }
}

.mobile-menu__items--level2 {
  padding: 0;
  border-top: 2px solid #fff;
}

.mobile-menu__items--level3 {
  @include font.family-frutiger-light-cond;
}

.mobile-menu__link {
  @include link.no-box-shadow;
  @include link.no-line-below;
  display: block;
  padding: 0.6rem 1.5rem;
  font-size: font.$medium-small-font-size;
}

.mobile-menu__link.mobile-menu__link--level1 {
  padding: 0.6rem;
}

.mobile-menu__social-links {
  padding: 1rem 1.3rem;
  margin: 0;
}

.mobile-meta-menu {
  @include colorscheme-dark-on-white;
  list-style-type: none;
  padding: 1rem 0;
  margin: 0;
}

.mobile-meta-menu__link {
  @include link.no-box-shadow;
  @include link.no-line-below;
  display: block;
  padding: 0.2rem 1.3rem;
}
