.infobox__content {
  padding: 0.25rem;
}

.infobox__header {
  margin: 1rem;
}

.infobox__body {
  margin: 1rem 1rem 1.5rem;
}
