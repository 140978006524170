.collapse:not([data-is-visible]) {
  display: none;
}

.collapsing {
  @include transition(height 0.2s ease);
  height: 0;
}

.collapse:not([data-is-visible='show']),
.collapsing {
  overflow: hidden;
}
