@use 'bootstrap/scss/functions' as bootstrap-functions;
@use 'sass:math';

:root {
  // Define mid animation colors as CSS properties, so that we can use them in JS and don't have to define them all over again.
  --smallTriangleMid: #87778e;
  --bigTriangleMid: #bba0ad;
  --trianglesOverlapping: #634b60;
  --hrkRed: #990000;
  --hrkRose: #634b60;

  --smallStepOne: #30567f;
  --smallStepTwo: #3d6085;
  --smallStepThree: #5a6889;
  --smallStepFour: #71728c;
  --smallStepFive: #8a788d;
  --smallStepSix: #a08191;
  --smallStepSeven: #b48793;
  --smallStepEight: #cd8f95;
  --smallStepNine: #d08385;
  --smallStepTen: #c97d7d;

  --bigStepOne: #97aabf;
  --bigStepTwo: #9da8bb;
  --bigStepThree: #a9a5b6;
  --bigStepFour: #b3a1b1;
  --bigStepFive: #bda0aa;
  --bigStepSix: #c79ca6;
  --bigStepSeven: #d09ba2;
  --bigStepEight: #d7989c;
  --bigStepNine: #d28587;
  --bigStepTen: #c97d7d;

  --overlappingStepOne: #97aabf;
  --overlappingStepTwo: #97aabf;
  --overlappingStepThree: #3c4260;
  --overlappingStepFour: #514761;
  --overlappingStepFive: #654c5f;
  --overlappingStepSix: #7b4f60;
  --overlappingStepSeven: #91515d;
  --overlappingStepEight: #aa5459;
  --overlappingStepNine: #a72b2d;
  --overlappingStepTen: #990000;
}

// Color theme
//
// Weight: -1
//
// Styleguide color.theme

// Main colors
//
// Colors:
// $primary:    #990000    - main theme color
// $dark:       #1e1e1e    - for text and dark gray backgrounds ($gray-900)
// $secondary:  #4b4b4b    - secondary theme color ($gray-700)
// $light:      #f4f4f4    - for light gray backgrounds ($gray-100)
// $info:       #064a63    - for information text
// $warning:    #d69999    - for warning text
// $danger:     #d71414    - for errors and danger alerts
// $success:    #006e1e    - for success notices
//
// Weight: -1
//
// Styleguide color.theme.main

// Grayscale
//
// Colors:
// $white:      #ffffff
// $gray-100:   #f4f4f4
// $gray-200:   #e5e5e5
// $gray-300:   #d2d2d2
// $gray-400:   #c0ccd8
// $gray-500:   #909090
// $gray-600:   #707070
// $gray-700:   #4b4b4b
// $gray-800:   #2a2a2a
// $gray-900:   #1e1e1e
// $black:      #000000
//
// Styleguide color.theme.gray

$hrk: #990000;
$rose: #d69999;

// Overrides for Bootstrap variables:

$blue: #30567f;
$yellow: #ffc107;
$red: #d71414;
$green: #006e1e;

$white: #ffffff;
$gray-100: #f4f4f4;
$gray-200: #e5e5e5;
$gray-300: #d2d2d2;
$gray-400: #b9b9b9;
$gray-500: #979797;
$gray-600: #707070;
$gray-700: #4b4b4b;
$gray-800: #2a2a2a;
$gray-900: #1e1e1e;
$black: #000000;

$blue-050: #f4f6f8;
$blue-100: #ebeef3;
$blue-150: #e0e6ec;
$blue-200: #c0ccd8;
$blue-300: #97aabf;
$blue-400: #6e88a5;
$blue-600: #213c59;

// TYPO3 Standard variables

$ce-margin-extra-small: 1rem;
$ce-margin-small: 2rem;
$ce-margin-medium: 3rem;
$ce-margin-large: 4rem;
$ce-margin-extra-large: 5rem;
$ce-padding-extra-small: 1rem;
$ce-padding-small: 2rem;
$ce-padding-medium: 3rem;
$ce-padding-large: 4rem;
$ce-padding-extra-large: 5rem;
$ce-border-width: 2px;
$ce-border-color: #dedede;
$ce-table-padding-top: 0.5em;
$ce-table-padding-left: 0.75em;
$ce-table-border-width: 2px;

// Menu

$menubar-max-width: 1250px;

// Overrides for Bootstrap variables:
@import '../bootstrap/variables'; /* stylelint-disable-line */

// Aspect ratio

$equilateral-triangle-altitude: math.sqrt(0.75);
$reciprocal-altitude: math.div(1, $equilateral-triangle-altitude);

// Z-indexes for content and for background triangles

$zindex-triangles: 0;
$zindex-content: 10;
$zindex-to-top: 10;
