.shariff {

  > ul {
    flex-direction: column;
    gap: 1rem;

    @include media-breakpoint-up('sm') {
      flex-direction: row;
    }
  }
}

.shariff .orientation-horizontal .shariff-button {
  margin-right: 0;
  margin-bottom: 0;
}

.shariff .orientation-horizontal.button-style-standard .shariff-button {
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-up('sm') {
    width: 50%;
    flex: 1 0;
  }

  @include media-breakpoint-up('md') {
    max-width: 145px;
  }
}

.shariff .orientation-horizontal .shariff-button .share_text {
  display: initial;
}
